<template>
  <Layout>
    <PageHeader :title="title" :items="PageHeaderItems" />
    <div class="bg-white" style="overflow-x: auto;">
      <div class="card p-4" style="max-height: 55%;box-shadow: none;">
        <Search
          v-model:searchInfo="params"
          v-model:highLevelSearch="highLevelSearch"
          v-model:showCheckbox="showCheckbox"
          :handlerClickTable="handlerClickTable"
          :search_list="search_list"
          :chooseTableInfo="chooseTableInfo"
          :data-type="data_type"
          @initTextile="initTextile"
        ></Search>
      </div>
      <div class="card mb-0" style="box-shadow: none;">
        <div>
          <Form
            :tableInfo="tableData"
            :table-loading="tableDataLoading"
            :highLevelSearch="highLevelSearch"
            :showCheckbox="showCheckbox"
            :data-type="data_type"
            @handlerChooseChart="handlerChooseChart"
            @initTextile="initTextile"
          ></Form>
        </div>
        <Pagination
          v-if="pagination.total"
          v-model:page="pagination.currentPage"
          v-model:perPage="pagination.pageSize"
          :pages="pagination.pageOption"
          :prePageOption="pagination.pageSizeOption"
          :tab_info_length="pagination.total"
          class="me-5 my-3"
        >
        </Pagination>
      </div>
    </div>
  </Layout>
</template>

<script>
import Search from "./Search";
import Form from "./Form";
import { getTextileBedwareList,
  getTextileHomeDecorList,
  getTextileIndustrialList,
  getTextileKitchenList
} from "@/service";
import { tableMixin } from "@/mixins/Table1.js";
import _ from "loadsh";
export default {
  components: {
    Search,
    Form,
  },
  mixins: [tableMixin],
  data() {
    return {
      // PageHeaderT: this.$t("textile.title"),
      // PageHeaderItems: [
      //   {
      //     text: this.$t("textile.title"),
      //     active: true,
      //     href: "/textile",
      //   },
      // ],
      search_list: {},
      showCheckbox: false,
      highLevelSearch: false,
      handlerClickTable: false,
      chooseTableInfo: null,
    };
  },
  computed: {
    data_type() {
      return this.$route.name
    },
    title() {
      if (this.data_type === 'textile_kitchen') {
        return this.$t("textile_kitchen.title")
      } else if (this.data_type === 'textile_industrial') {
        return this.$t("textile_industrial.title")
      } else if (this.data_type === 'textile_home_decor') {
        return this.$t("textile_home_decor.title")
      } else if (this.data_type === 'textile_bedware') {
        return this.$t("textile_bedware.title")
      } else {
        return ''
      }
    },
    currentRoute() {
      return `/${this.data_type}`
    },
    PageHeaderItems() {
      return [{
        text: this.title,
        active: true,
        href: this.currentRoute,
      }]
    },
    listFunc() {
      if (this.data_type === 'textile_kitchen') {
        return getTextileKitchenList
      } else if (this.data_type === 'textile_industrial') {
        return getTextileIndustrialList
      } else if (this.data_type === 'textile_home_decor') {
        return getTextileHomeDecorList
      } else if (this.data_type === 'textile_bedware') {
        return getTextileBedwareList
      } else {
        return null
      }
    }
  },
  methods: {
    handlerChooseChart(info, status) {
      let chartInfo = _.cloneDeep(info);
      this.chooseTableInfo = _.merge(
        {},
        chartInfo.skin_comfort,
        chartInfo.hand_feel,
        chartInfo.score_dat
      );
      this.handlerClickTable = status;
    },
    initTextile() {
      this.getFirstPage();
    },
    requestList(params) {
      let funcName = this.listFunc;
      if (!funcName) {
        return
      }
      funcName(params)
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.updateData(res.payload);
            this.search_list = res.payload.search_list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    '$route' (to) {
      console.log(to.name)
      this.initTextile()
    }
  }
};
</script>

<style lang="scss">
.table-responsive {
  min-height: 60vh;
  max-height: 60vh;
}

.mask_model {
  width: 100vw;
  height: 100vh;
  background-color: rgba(47, 79, 79, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
